// See React on Rails Pro documentation for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from '../utils/retry';

// Components
export const Footer = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Footer" */
  './shared/Footer')));

// Routes
export const Search = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Search" */
  './public/search/Search')));
export const Support = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Support" */
  './public/Support')));
export const BlogPost = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BlogPost" */
  './public/blog/BlogPost')));
export const Blog = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Blog" */
  './public/blog/Blog')));
export const Truck = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Truck" */
  './public/Truck')));
export const Location = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Location" */
  './public/Location')));
export const Home = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Home" */
  './public/pages/Home')));
export const Services = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Services" */
  './public/pages/Services')));
export const OfficeParks = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "OfficeParks" */
  './public/pages/OfficeParks')));
export const Residential = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Residential" */
  './public/pages/Residential')));
export const Breweries = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Breweries" */
  './public/pages/Breweries')));
export const Privacy = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Privacy" */
  './public/pages/Privacy')));
export const Terms = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Terms" */
  './public/pages/Terms')));
export const Error404Page = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Error404Page" */
  './public/Error404Page')));
export const Error500Page = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Error500Page" */
  './public/Error500Page')));
export const CommunityEvents = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CommunityEvents" */
  './public/pages/CommunityEvents')));
export const CateringRegister = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CateringRegister" */
  './auth/CateringRegister')));
export const Login = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Login" */
  './auth/Login')));
export const ChooseAccount = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ChooseAccount" */
  './auth/ChooseAccount')));
export const Register = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Register" */
  './auth/Register')));
export const Vendors = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Vendors" */
  './public/Vendors')));
export const Catering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Catering" */
  './public/pages/Catering')));
export const WeddingCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "WeddingCatering" */
  './public/pages/WeddingCatering')));
export const CorporateCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CorporateCatering" */
  './public/pages/CorporateCatering')));
export const DessertCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "DessertCatering" */
  './public/pages/DessertCatering')));
export const BurgerCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BurgerCatering" */
  './public/pages/BurgerCatering')));
export const AsianCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "AsianCatering" */
  './public/pages/AsianCatering')));
export const TacoCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "TacoCatering" */
  './public/pages/TacoCatering')));
export const BbqCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BbqCatering" */
  './public/pages/BbqCatering')));
export const PizzaCatering = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "PizzaCatering" */
  './public/pages/PizzaCatering')));
export const DeletionStatus = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "DeletionStatus" */
  './public/DeletionStatus')));
export const ForgotPassword = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ForgotPassword" */
  './auth/ForgotPassword')));