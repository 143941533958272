import dayjs from 'dayjs';

import IsoMorphUtils from './isoMorphUtils';

export function throttle(func, timeFrame) {
  let lastTime = 0;
  return function (...args) {
    let now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

export function upperFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export function titleCase(string) {
  return string.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

// Sorts an array of objects by key, with optional function to transform the value
export function sortBy(array, key, transform = (a) => a) {
  const sortFunction = (a, b) => (transform(a[key]) > transform(b[key])) ? 1 : ((transform(b[key]) > transform(a[key])) ? -1 : 0);
  return array.concat().sort(sortFunction);
};

export function unionBy(array1, array2, func) {
  return [array1, array2].flat().filter(
    (set => (o) => set.has(func(o)) ? false : set.add(func(o)))(new Set()),
  );
}

// Get the intersection of two arrays
// Note: uses simple equality, does not work with arrays of objects!
export function arrayIntersection(array1, array2) {
  if (!array1 || !array2) return [];
  let arrays = [array1, array2];
  return arrays.reduce((a, b) => a.filter(c => b.includes(c)));
}

export function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }
  return arr.slice(1).reduce(function(xs, x, i) {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}

export function isMobile() {
  if (typeof window === 'object' && window.innerWidth <= 900) {
    return true;
  }
  return false;
}

export function formatPhoneNumber(rawNumber) {
  const s2 = (`${rawNumber}`).replace(/\D/g, '');
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  let result = (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
  if (!result) {
    result = rawNumber;
  }
  return result;
}

export function formatDisplayTime(time) {
  let displayTime = dayjs(time);
  if (displayTime.format('mm') == '00') {
    displayTime = displayTime.format('ha');
  } else {
    displayTime = displayTime.format('h:mma');
  }
  return displayTime;
}

export function numberWithCommas(x) {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cityState(address) {
  if (!address) return null;
  const addressArray = address.trim().split(',');
  return addressArray[addressArray.length - 3] + ", " + addressArray[addressArray.length - 2];
}

export function fixBodyScroll() {
  let scrollY = 0;
  if (window.scrollY > 0) {
    scrollY = window.scrollY;
  }
  document.body.style = `width: 100%; position: fixed; top: ${-scrollY}px;`
  return scrollY;
}

export function unfixBodyScroll(scrollY = 0) {
  document.body.style = '';
  window.scrollTo(0, scrollY);
}

export function getUrl() {
  if (IsoMorphUtils.serverSide) {
    return `${IsoMorphUtils.railsContext.scheme}:${IsoMorphUtils.railsContext.href}`;
  }
  return  window.location.href;
}

export function removeArrayItem(array, value) {
  var index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

// Return object with only truthy keys
export function pickBy(object) {
  const obj = {};
  for (const key in object) {
    if (object[key]) {
      obj[key] = object[key];
    }
  }
  return obj;
}